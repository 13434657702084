import axios from "@/config/axios.config"
import { CompanyProp } from "./interface"
import { ICountry } from "@/client/constants/constants"
import { MessageBO } from "@/server/features/conversations/bo"
import { Company } from "@/server/features/companies/bo"
import { ConversationSummaryBO } from "@/server/features/conversations/bo"
import { UpdateOp } from "@/server/features/watchlists/bo"
import { ScreenerResult, ScreenerApiPayload } from "@/server/features/screener/api-type"
import { ScreenerQueryDocument } from "@/server/features/screener/db/query-schema"
import { ScreenerResponseDocument } from "@/server/features/screener/db/response-schema"


export const getFilingsData = async (
	filings: string[],
	page: number,
	sectors: string[],
	industries: string[],
	years: string[],
	quarters: string[],
	companies: CompanyProp[],
	countryCode: ICountry,
	marketcap_category: string[],
	watchlistIds?: string[]
) => {
	try {
		const sectorParam =
			sectors.length > 0
				? `&sector=${encodeURIComponent(sectors.join(","))}`
				: ""
		const industryParam =
			industries.length > 0
				? `&industry=${encodeURIComponent(industries.join(","))}`
				: ""
		const yearQuarterParam =
			quarters.length > 0 ? `&quarter=${quarters.join(",")}` : ""
		const yearParam = years.length > 0 ? `&year=${years.join(",")}` : ""
		const types = filings.length > 0 ? `document_type=${filings.join(",")}` : ""
		const company =
			companies.length > 0
				? `&company_id=${companies.map(company => company.company_id).join(",")}`
				: ""
		const watchlistParam =
			watchlistIds && watchlistIds.length > 0
				? `&watchlist=${watchlistIds.join(",")}`
				: ""
		const marketcapParam =
			marketcap_category && marketcap_category.length > 0
				? `&marketcap_category=${marketcap_category.join(",")}`
				: ""
		const searchParams = `${types}${industryParam}${sectorParam}${yearQuarterParam}${yearParam}${company}${marketcapParam}${watchlistParam}`
		const response = await axios.get(
			`/api/${countryCode}/v0/documents?${searchParams}&page=${page}&limit=10`
		)
		return response.data
	} catch (error) {
		console.error("Error while fetching filings data", error)
		throw error
	}
}

export const getCompanySearchData = async (
	country: ICountry,
	query: string
) => {
	if (query.trim() === "") {
		return []
	}
	try {
		const url = `/api/${country}/v0/companies/search?query=${encodeURIComponent(query)}`
		const response = await axios.get(url)
		return response.data.data
	} catch (error) {
		console.log("Error while fetching company search data", error)
		return null
	}
}

export const getCompanies = async (
	country: ICountry,
	companyIds?: string,
	queryParams?: string,
	isUnpaginated?: boolean
) => {
	try {
		const params = new URLSearchParams(queryParams)
		if (companyIds) {
			params.set('company_id', companyIds)
		}
		if (isUnpaginated) {
			params.set('fetch_all', 'true')
		}

		const url = `/api/${country}/v0/companies?${params.toString()}`
		const response = await axios.get(url)
		return response.data.data.map((item: Company) => {
			const { id, company_name, ticker, exchange_tickers, cik } = item
			return {
				id: id,
				name: company_name,
				ticker: ticker,
				exchange_tickers: exchange_tickers,
				cik: cik,
			}
		})
	} catch (error) {
		console.log("Error while fetching company data", error)
		return null
	}
}

export const getTextSearch = async (
	country: ICountry,
	searchParams: string,
	sub_route: string
) => {
	try {
		const response = await axios.get(
			`/api/${country}/v0/documents/${sub_route}/full-text-search?${searchParams}&significance=true&append_to_response=count_by_attribute&limit=10`
		)
		return response.data
	} catch (error) {
		console.log("Error while fetching text search results", error)
		return null
	}
}

export const getAnnouncementsData = async (
	countryCode: string, //@typescript-eslint/no-unused-vars
	searchParams: string, // @typescript-eslint/no-unused-vars,
	sub_route: string
) => {
	try {
		const api = `/api/${countryCode}/v0/documents/${sub_route}?limit=10&${searchParams}&significance=true&append_to_response=count_by_attribute`
		const response = await axios.get(api)
		return response.data
	} catch (error) {
		console.log("Error while fetching announcements", error)
		return null
	}
}

interface Filters {
	types?: string[]
	sectors?: string[]
	industries?: string[]
	years?: string[]
	quarters?: string[]
	company_ids?: string[]
	watchlist?: string[]
	marketcap_category?: string[]
}

export const getFullTextSearchData = async (
	filings: string[],
	sectors: string[],
	industries: string[],
	companies: CompanyProp[],
	years: string[],
	quarters: string[],
	marketcap_category: string[],
	query: string,
	countryCode: string,
	watchlistIds?: string[]
) => {
	try {
		const filters: Filters = {}
		if (filings.length > 0) {
			filters.types = filings
		}
		if (sectors.length > 0) {
			filters.sectors = sectors
		}
		if (industries.length > 0) {
			filters.industries = industries
		}
		if (quarters.length > 0) {
			filters.quarters = quarters
		}
		if (years.length > 0) {
			filters.years = years
		}
		if (marketcap_category.length > 0) {
			filters.marketcap_category = marketcap_category
		} else {
			// fix default years
			const currYear = new Date().getFullYear()
			filters.years = [currYear + 1, currYear, currYear - 1, currYear - 2].map(
				String
			)
		}
		if (companies.length > 0) {
			filters.company_ids = companies.map(company => company.company_id)
		}
		if (watchlistIds && watchlistIds.length > 0) {
			filters.watchlist = watchlistIds
		}
		const response = await axios.post(
			`/api/${countryCode}/v0/documents/full-text-search`,
			{
				query: query,
				filters: filters,
			}
		)
		return response.data
	} catch (error) {
		console.error("Error fetching data:", error)
		throw error
	}
}

export const mockApiResponse = (
	data: unknown,
	delay = 0,
	shouldFail = false
) => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			if (shouldFail) {
				reject(data)
			} else {
				resolve(data)
			}
		}, delay)
	})
}

export const createWatchList = async (country: ICountry) => {
	try {
		const response = await axios.post(`/api/${country}/watchlists`, {})
		return response.data
	} catch (error) {
		console.error("Error creating watchlist:", error)
		return null
	}
}

export const getAllUserWatchlists = async (country: ICountry) => {
	try {
		const response = await axios.get(`/api/${country}/watchlists`)
		return response.data
	} catch (error) {
		console.log("Error while fetching watchlists", error)
		return null
	}
}

export const getWatchListDetails = async (country: ICountry, id: string) => {
	try {
		const response = await axios.get(`/api/${country}/watchlists/${id}`)
		return response.data
	} catch (error) {
		console.log("Error while fetching watchlist details", error)
		return null
	}
}

export const updateCompanyWatchList = async (
	country: ICountry,
	watchlistId: string,
	companyId: string | string[], // accept single company id or array of company ids
	option: UpdateOp
) => {
	try {
		const url = `/api/${country}/watchlists/${watchlistId}`
		const data = {
			op: option,
			field: "companies",
			values: Array.isArray(companyId) ? companyId : [companyId],
		}
		const response = await axios.patch(url, data)
		return response.data
	} catch (error) {
		console.log("Error while updating watchlist", error)
		throw error
	}
}

export const updateNotificationSettings = async (
	country: ICountry,
	watchlistId: string,
	documentTypes: string[]
) => {
	try {
		const url = `/api/${country}/watchlists/${watchlistId}/notification-settings`
		const data = {
			document_types: documentTypes,
		}
		const response = await axios.put(url, data)
		return response.data
	} catch (error) {
		console.log("Error while updating notification settings", error)
		return null
	}
}

export const getAllUserConversations = async (
	country: ICountry
): Promise<ConversationSummaryBO[]> => {
	try {
		const api = `/api/${country}/conversations/`
		const response = await axios.get(api)
		const { status, data } = response.data
		if (status === "success") {
			return data
		}
		throw new Error("Failed to fetch past conversations")
	} catch (error) {
		console.error("Failed to fetch past conversations", error)
		throw error
	}
}

export const getConversationById = async (
	country: ICountry,
	conversationId: string | undefined
): Promise<{ conversation_id: string | undefined; messages: MessageBO[] }> => {
	try {
		const api = `/api/${country}/conversations/${conversationId}`
		const response = await axios.get(api)
		const { status, data } = response.data
		if (status === "success") {
			return { conversation_id: data.id, messages: data.messages }
		}
		throw new Error("Failed to fetch past conversations")
	} catch (error) {
		console.error("Failed to fetch past conversations", error)
		throw error
	}
}

export const getAccountUsage = async (country: ICountry, metrics: string) => {
	try {
		const response = await axios.get(
			`/api/${country}/account-usage?metrics=${metrics}`
		)
		const { status, data } = response.data
		if (status === "success") {
			return data
		}
		throw new Error("Failed to fetch account usage")
	} catch (error) {
		console.error("Error fetching account usage:", error)
		return null
	}
}

export const createSharedInsight = async (
	country: ICountry,
	conversationId: string
) => {
	try {
		const response = await axios.post(`/api/${country}/share/chat/`, {
			conversationId: conversationId,
		})
		return response.data
	} catch (error) {
		console.error("Error creating shared insight:", error)
		return null
	}
}

export const getSharedConversation = async (token: string) => {
	try {
		const response = await axios.get(`/api/share/chat/${token}`)
		const { status, data } = response.data
		if (status === "success") {
			return data
		}
		throw new Error("Failed to fetch shared conversation")
	} catch (error) {
		console.error("Error fetching shared conversation:", error)
		return null
	}
}

export const addEmail = async (email: string) => {
	if (!email || !email.includes("@")) {
		return {
			success: false,
			message: "Please enter a valid email address",
		}
	}

	try {
		await axios.post("/api/email", { email })
		return {
			success: true,
			message: "Email added successfully",
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// Check specifically for work email validation error
		if (error?.response?.status === 422) {
			return {
				success: false,
				message: "Please enter a valid work email",
			}
		}

		return {
			success: false,
			message: "Failed to add email. Please try again.",
		}
	}
}

export const runScreener = async (country: ICountry, payload: ScreenerApiPayload): Promise<{
	results: ScreenerResult;
	query_id: string;
	result_id: string;
} | null> => {
	try {
		const {
			data: { data, status },
		} = await axios.post(`/api/${country}/v0/documents/screener`, payload)
		if (status === "success") {
			return {
				results: data.results,
				query_id: data.query_id,
				result_id: data.result_id
			}
		}
		throw new Error("Failed to fetch results")
	} catch (error) {
		console.error("Error running screener:", error)
		return null
	}
}

export const fetchScreenerQueries = async (country: ICountry): Promise<ScreenerQueryDocument[] | null> => {
	try {
		const response = await axios.get(`/api/${country}/v0/documents/screener/queries`)
		const { status, data } = response.data
		if (status === "success") {
			return data
		}
		throw new Error("Failed to fetch screener queries")
	} catch (error) {
		console.error("Error fetching screener queries:", error)
		return null
	}
}

export const fetchScreenerDetails = async (country: ICountry, queryId: string): Promise<ScreenerResponseDocument | null> => {
	try {
		const response = await axios.get(`/api/${country}/v0/documents/screener/queries/${queryId}`)
		const { status, data } = response.data
		if (status === "success") {
			return data
		}
		throw new Error("Failed to fetch screener details")
	} catch (error) {
		console.error("Error fetching screener details:", error)
		return null
	}
}
